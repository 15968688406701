import Cookies from "js-cookie";

const API_URL = "https://bf-api.solutioweb.com.br/vendedores";

export const getVendedorById = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/${id}`);
    if (!response.ok) {
      throw new Error("Erro ao buscar vendedor");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar vendedor:", error);
    throw error;
  }
};

export const getVendedores = async (search = "") => {
  try {
    const url = search
      ? `${API_URL}/lista?search=${encodeURIComponent(search)}`
      : `${API_URL}/lista`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Erro ao buscar lista de vendedores");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao buscar lista de vendedores:", error);
    throw error;
  }
};

export const createVendedor = async (vendedor: any) => {
  try {
    const formData = new FormData();
    formData.append("nome", vendedor.nome);
    formData.append("email", vendedor.email);
    formData.append("telefone", vendedor.telefone);
    formData.append("empresa", vendedor.empresa);
    formData.append("produtos", JSON.stringify(vendedor.produtos));

    const response = await fetch(API_URL, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao criar vendedor:", error);
    throw error;
  }
};

export const updateVendedor = async (vendedor: any) => {
  try {
    const formData = new FormData();
    formData.append("nome", vendedor.nome);
    formData.append("email", vendedor.email);
    formData.append("telefone", vendedor.telefone);
    formData.append("empresa", vendedor.empresa);
    formData.append("produtos", JSON.stringify(vendedor.produtos));

    const response = await fetch(`${API_URL}/${vendedor.id}`, {
      method: "PUT",
      body: formData,
    });

    if (!response.ok) {
      return { error: "Houve uma falha. Tente mais tarde." };
    }

    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Erro ao atualizar vendedor:", error);
    throw error;
  }
};

export const deleteVendedor = async (id: number) => {
  try {
    const response = await fetch(`${API_URL}/${id}`, {
      method: "DELETE",
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro ao deletar vendedor:", error);
    throw error;
  }
};
