import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";

interface InputDecimal2_2_ComponentProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputDecimal2_2_Component: React.FC<InputDecimal2_2_ComponentProps> = (
  props
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      const $input = $(inputRef.current);

      $input.mask("###.###.###,00", {
        reverse: true,
      });
    }
  }, [props]);

  return (
    <input className="form-control" type="text" ref={inputRef} {...props} />
  );
};

export default InputDecimal2_2_Component;
