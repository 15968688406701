import React, { useState, useEffect } from "react";
import HeaderComponent from "../components/HeaderComponent";
import MenuComponent from "../components/MenuComponent";
import { useNavigate } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { IoAddCircleOutline, IoSearch } from "react-icons/io5";
import { Modal, Button, Col, Row, Table } from "react-bootstrap";
import { getProdutos } from "./../services/produtosService";
import InputDecimal2_2_Component from "../components/Inputdecimal_2_2_Component";
import { MdEdit } from "react-icons/md";
import {
  getVendedores,
  getVendedorById,
  createVendedor,
  updateVendedor,
} from "../services/vendedoresService";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

export interface Vendedor {
  id: number;
  nome: string;
  email: string;
  telefone: string;
  empresa: string;
  produtos: any[];
}

export interface Produto {
  id: number;
  descricao: string;
}

const VendedoresPage: React.FC = () => {
  const handleClose = () => setShow(false);

  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [vendedores, setVendedores] = useState<Vendedor[]>([]);
  const [newVendedor, setNewVendedor] = useState<Vendedor>({
    id: 0,
    nome: "",
    email: "",
    telefone: "",
    empresa: "",
    produtos: [],
  });
  const [selectedProdutos, setSelectedProdutos] = useState<
    { value: string; label: string }[]
  >([]);
  const [produtoOptions, setProdutoOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [isEditing, setIsEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [alerta, setAlerta] = useState("");
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const [tabelaProdutos, setTabelaProdutos] = useState<
    { id: string; descricao: string; preco: string }[]
  >([]);

  useEffect(() => {
    fetchVendedores();
    fetchProdutos();
  }, []);

  const fetchVendedores = async (search = "") => {
    try {
      const data = await getVendedores(search);
      setVendedores(data);
    } catch (error) {
      console.error("Erro ao buscar vendedores:", error);
    }
  };

  const fetchProdutos = async () => {
    try {
      const response = await getProdutos();

      const formattedProdutos = response.map(
        (produto: { id: number; descricao: string }) => ({
          value: produto.id.toString(),
          label: produto.descricao,
        })
      );

      setProdutoOptions(formattedProdutos);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  const editVendedor = async (id: number) => {
    try {
      const vendedor = await getVendedorById(id);

      const produtosArray = Array.isArray(vendedor.produtos)
        ? vendedor.produtos
        : JSON.parse(vendedor.produtos);

      const produtosSelecionados = produtosArray.map((produto: any) => ({
        value: produto.value,
        label: produto.label,
      }));

      const produtosTabela = produtosArray.map((produto: any) => ({
        id: produto.value,
        descricao: produto.label,
        preco: produto.preco,
      }));

      setNewVendedor({
        ...vendedor,
        produtos: produtosArray,
      });
      setSelectedProdutos(produtosSelecionados);
      setTabelaProdutos(produtosTabela);
      setIsEditing(true);
      setShow(true);
    } catch (error) {
      console.error("Erro ao buscar vendedor:", error);
    }
  };

  const handleProdutoChange = (selectedOptions: any) => {
    setNewVendedor((prevState) => ({
      ...prevState,
      produtos: selectedOptions || [],
    }));

    setSelectedProdutos(selectedOptions);

    const novosProdutos = selectedOptions.map((produto: any) => {
      const produtoExistente = tabelaProdutos.find(
        (p) => p.id === produto.value
      );

      return {
        id: produto.value,
        descricao: produto.label,
        preco: produtoExistente ? produtoExistente.preco : 0,
      };
    });

    setTabelaProdutos((prev) => {
      const produtosAtuaisIds = selectedOptions.map(
        (produto: any) => produto.value
      );
      const tabelaFiltrada = prev.filter((produto) =>
        produtosAtuaisIds.includes(produto.id)
      );

      novosProdutos.forEach((novoProduto: any) => {
        const index = tabelaFiltrada.findIndex((p) => p.id === novoProduto.id);
        if (index >= 0) {
          tabelaFiltrada[index] = novoProduto;
        } else {
          tabelaFiltrada.push(novoProduto);
        }
      });

      return tabelaFiltrada;
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "telefone") {
      let cleanedValue = value.replace(/\D/g, "");

      let maskedValue = cleanedValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{4})\d+?$/, "$1");

      setNewVendedor({ ...newVendedor, [name]: maskedValue });
    } else {
      setNewVendedor({ ...newVendedor, [name]: value });
    }
  };

  const add = () => {
    setNewVendedor({
      id: 0,
      nome: "",
      email: "",
      telefone: "",
      empresa: "",
      produtos: [],
    });
    setIsEditing(false);
    setShow(true);
  };

  const handleSubmit = async () => {
    if (!newVendedor.nome || !newVendedor.email) {
      setAlerta("Os campos Nome e E-mail são obrigatórios.");
      return;
    }

    try {
      // Mapear produtos com os valores do estado "tabelaProdutos"
      const produtosComPreco = selectedProdutos.map((produtoSelecionado) => {
        const produtoTabela = tabelaProdutos.find(
          (produto) => produto.id === produtoSelecionado.value
        );

        return {
          value: produtoSelecionado.value,
          label: produtoSelecionado.label,
          preco: produtoTabela ? produtoTabela.preco : "0", // Usa o valor diretamente
        };
      });

      const vendedorParaSalvar = {
        ...newVendedor,
        produtos: produtosComPreco, // Inclui produtos com preços no formato original
      };

      let result;
      if (isEditing) {
        result = await updateVendedor(vendedorParaSalvar);
      } else {
        result = await createVendedor(vendedorParaSalvar);
      }

      if (result.status === "error") {
        setAlerta(result.messages[0]);
      } else {
        fetchVendedores("");
        setShow(false);
        setAlerta(
          isEditing
            ? "Vendedor atualizado com sucesso."
            : "Vendedor cadastrado com sucesso."
        );
      }
    } catch (error) {
      setAlerta("Erro ao salvar vendedor.");
    }
  };

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (search.trim() === "" || search.trim().length >= 3) {
      fetchVendedores(search.trim());
    } else {
      setAlerta("O termo de pesquisa deve ter pelo menos 3 caracteres.");
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (event.target.value.trim() === "") {
      fetchVendedores("");
    }
  };

  const handlePrecoChange = (id: string, novoPreco: string) => {
    setTabelaProdutos((prev) =>
      prev.map((produto) =>
        produto.id === id ? { ...produto, preco: novoPreco } : produto
      )
    );
  };

  return (
    <>
      <HeaderComponent />
      <div className="page-content">
        <MenuComponent />
        <div className="content-wrapper">
          <div className="content-inner">
            <div className="page-header page-header-light">
              <div className="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
                <div className="d-flex">
                  <div className="breadcrumb">
                    <a
                      onClick={() => navigate("/")}
                      className="breadcrumb-item"
                    >
                      <IoMdHome /> &nbsp; Início
                    </a>
                    <span className="breadcrumb-item active">Vendedores</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title d-flex float-left">
                    <Button className="bt-1-ico" variant="indigo" onClick={add}>
                      <IoAddCircleOutline className="m-0-auto fs-2em" />
                    </Button>
                    <span className="fs-1-5em pl-2">Vendedores</span>
                  </h5>
                  <div className="float-right">
                    <form onSubmit={handleSearchSubmit}>
                      <div className="form-group form-group-feedback form-group-feedback-right mb-0 mt-1 min-w-400">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Pesquisar por nome, e-mail ou empresa"
                          value={search}
                          onChange={handleSearchChange}
                        />
                        <div className="form-control-feedback form-control-feedback-lg pt-0">
                          <button type="submit" className="btn btn-link p-0">
                            <IoSearch className="fs-1-7em" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Telefone</th>
                        <th>Empresa</th>
                        <th style={{ width: "100px", textAlign: "center" }}>
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendedores.map((vendedor) => (
                        <tr key={vendedor.id}>
                          <td>{vendedor.id}</td>
                          <td>{vendedor.nome}</td>
                          <td>{vendedor.email}</td>
                          <td>{vendedor.telefone}</td>
                          <td>{vendedor.empresa}</td>
                          <td style={{ textAlign: "center" }}>
                            <Button
                              variant="info"
                              size="sm"
                              onClick={() => editVendedor(vendedor.id)}
                            >
                              <MdEdit />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isEditing ? "Editar Vendedor" : "Novo Vendedor"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <div className="form-group">
                <label>Nome:</label>
                <input
                  type="text"
                  className="form-control"
                  name="nome"
                  value={newVendedor.nome}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={newVendedor.email}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label>Telefone:</label>
                <input
                  type="text"
                  className="form-control"
                  name="telefone"
                  value={newVendedor.telefone}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="form-group">
                <label>Empresa:</label>
                <input
                  type="text"
                  className="form-control"
                  name="empresa"
                  value={newVendedor.empresa}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <label>Produtos:</label>
                <Select
                  options={produtoOptions}
                  isMulti
                  placeholder="Selecione"
                  value={selectedProdutos}
                  onChange={handleProdutoChange}
                />
              </div>
            </Col>
            <Col lg={12}>
              {tabelaProdutos.length > 0 && (
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Produto</th>
                      <th>Preço (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelaProdutos.map((produto) => (
                      <tr key={produto.id}>
                        <td>{produto.descricao}</td>
                        <td>
                          <InputDecimal2_2_Component
                            type="text"
                            placeholder=""
                            value={produto.preco}
                            name="preco"
                            style={{ color: "#333", width: "100px" }}
                            onChange={(e: any) =>
                              handlePrecoChange(produto.id, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            {isEditing ? "Atualizar" : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        transition={Slide}
      />
    </>
  );
};

export default VendedoresPage;
